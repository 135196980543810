import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Card } from '@twilio-paste/card';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { Flex } from '@twilio-paste/flex';
import { FormInput, FormLabel, FormHelpText } from '@twilio-paste/form';
import { Grid, Column } from '@twilio-paste/grid';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@twilio-paste/tabs';
import { Text } from '@twilio-paste/text';
import { Toast } from '@twilio-paste/toast';
import { ErrorIcon } from '@twilio-paste/icons/esm/ErrorIcon';
import { SuccessIcon } from '@twilio-paste/icons/esm/SuccessIcon';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/patterns/notifications/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <Breadcrumb mdxType="Breadcrumb">
  <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
  <BreadcrumbItem to="/patterns" mdxType="BreadcrumbItem">Patterns</BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" variant="heading10" mdxType="Heading">
  {props.pageContext.frontmatter.title}
      </Heading>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
      <Box as="dl" display="flex" mdxType="Box">
  <Text as="dt" color="colorTextWeak" lineHeight="lineHeight30" mdxType="Text">
    <Box width="size10" mdxType="Box">Status</Box>
  </Text>
  <Text as="dd" mdxType="Text">alpha</Text>
      </Box>
      <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">This is an ALPHA pattern, and we need your feedback!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    This pattern is still going through feedback cycles, so if you have feedback or ideas related to this pattern,
    please create a <Anchor href="https://github.com/twilio-labs/paste/discussions" mdxType="Anchor">GitHub Discussion</Anchor> and let
    us know.
  </CalloutText>
      </Callout>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Ingredients`}</h2>
        <p>{`Each notification or feedback pattern uses one or more of these components.`}</p>
        <Grid gutter="space30" marginBottom="space80" mdxType="Grid">
  <Column mdxType="Column">
    <Stack orientation="vertical" spacing="space50" mdxType="Stack">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/alert" mdxType="Anchor">Alert</Anchor>
        </Heading>
      </Card>
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/toast" mdxType="Anchor">Toast</Anchor>
        </Heading>
      </Card>
    </Stack>
  </Column>
  <Column mdxType="Column">
    <Stack orientation="vertical" spacing="space50" mdxType="Stack">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/form-elements/input/#input-with-inline-error" mdxType="Anchor">Inline error on form fields</Anchor>
        </Heading>
      </Card>
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/modal" mdxType="Anchor">Modal</Anchor>
        </Heading>
      </Card>
    </Stack>
  </Column>
  <Column mdxType="Column">
    <Card mdxType="Card">
      <Heading as="h3" variant="heading40" mdxType="Heading">
        Component composition
      </Heading>
      <Paragraph marginBottom="space0" mdxType="Paragraph">
        Create a composition with <Anchor href="/components/card" mdxType="Anchor">Card</Anchor>, <Anchor href="/components/heading" mdxType="Anchor">
          Heading
        </Anchor>, and <Anchor href="/components/paragraph" mdxType="Anchor">Paragraph</Anchor> for in-page messaging.
      </Paragraph>
    </Card>
  </Column>
        </Grid>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`// import all components for Notification and feedback patterns

import {
  Alert,
  Toast,
  Input,
  Label,
  HelpText,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
  Card,
  Heading,
  Paragraph,
} from '@twilio-paste/core';
`}</code></pre>
        <h2>{`Usage`}</h2>
        <h3>{`General usage`}</h3>
        <p>{`Pairing thoughtful content writing with the appropriate UI elements can help you establish trust with customers by notifying them of relevant information at just the right time. Use these patterns only when the user `}<strong parentName="p">{`needs`}</strong>{` to be notified.`}</p>
        <p>{`In general, notifications should close when a user leaves the page. A notification should follow a user through the product only in the most critical cases, like an alert informing a customer that their paid account won’t renew soon because of expired billing information.`}</p>
        <p>{`Too many notifications that appear for too long may lead to alert fatigue, causing users to ignore important information.`}</p>
        <p>{`There are 3 levels of notifications and feedback:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`System-initiated notifications:`}</strong>{` Information that’s not triggered by a user action and is related to system issues.`}</li>
          <li parentName="ul"><strong parentName="li">{`Feedback indicators:`}</strong>{` Acknowledgment of a user action, like submitting a form or pressing a “Copy code” button.`}</li>
          <li parentName="ul"><strong parentName="li">{`Notification centers and reminders:`}</strong>{` Currently, these are highly specific to products’ business needs and should be treated as a product feature, rather than a pattern. While the implementation of a notification center might use Paste components, guidelines specific to a notification center won’t be covered in this page.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Notifications in high-emotion or high-stress situations</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    For high-emotion or high-stress parts of the customer journey, use a component that appropriately matches the
    customer's level of emotion. For example, to confirm that a customer has successfully upgraded their account, you
    may want to use a full-page confirmation that celebrates the moment, instead of a simple toast.
  </CalloutText>
        </Callout>
        <p>{`Notifications and feedback can come in 4 variants:`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Success:`}</strong>{` Confirms a successful or completed action.`}</li>
          <li parentName="ul"><strong parentName="li">{`Error:`}</strong>{` Communicates critical information that's actively preventing a user from continuing a flow.`}</li>
          <li parentName="ul"><strong parentName="li">{`Warning:`}</strong>{` Communicates critical information that will help a user avoid a potential issue, but won’t prevent them from continuing their immediate goal.`}</li>
          <li parentName="ul"><strong parentName="li">{`Neutral:`}</strong>{` Informs a user of general changes, or processes that have been triggered by a user action but will take time to finish.`}</li>
        </ul>
        <h3>{`Accessibility`}</h3>
        <p>{`Give users enough time to read a notification message, make a decision, and act on their decision. Users’ reading speeds, vision levels, literacy levels, dexterity, and familiarity with Twilio products can all affect how much time they’ll need to interact with a notification.`}</p>
        <p>{`Visit the `}<a parentName="p" {...{
            "href": "/components/alert"
          }}>{`Alert`}</a>{`, `}<a parentName="p" {...{
            "href": "/components/modal"
          }}>{`Modal`}</a>{`, `}<a parentName="p" {...{
            "href": "/form-elements/input"
          }}>{`Form element`}</a>{`, and `}<a parentName="p" {...{
            "href": "/components/toast"
          }}>{`Toast`}</a>{` pages for more specific guidelines on how to implement each component accessibly.`}</p>
        <h2>{`Notification and feedback levels`}</h2>
        <h3>{`System-initiated notifications`}</h3>
        <p>{`To communicate information from the system that isn't triggered by a user action, use an `}<strong parentName="p"><a parentName="strong" {...{
              "href": "/components/alert"
            }}>{`alert`}</a></strong>{`, especially for high-priority or time-sensitive changes to the system.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.71568627450981%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABFElEQVQoz62P30rDMBjF+1QqXmndk+zO6a3zQfY8XS+2oeu86BAneOON5k9jUm3ydSltmn1zDHEoMvDwI5yccOAkuO4Pjg66Z6c9pBNedsKLDXgNT85/pBP2jg+7/atBME+fhtFsMpmPx2k8nMXxHRJFyWiUJsliOn3YIfnk9ub+cfEc1E3dtM57XxSaZ4Jx/kIoZUwbg2Hbtv4X4UsAUBaFKW3lK9sQYjNhGa8ysWTcEFpShgChsPU+z52Udf7eNA7LS62hqmoLpXwlinFFEbY+N57xnGc5WxtJ2UcmQCln7beygZJwkb0pLqSQqtBGA2BYGEA0Gg0YIlVd4+yvssXZ+A3n2q38X9ot76X/K+PSvcDyCmeWFE8qLf0SAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen with alert informing users of a server error",
              "title": "Screen with alert informing users of a server error",
              "src": "/static/74b25d6a91f8d2cd00781ab7976d2b24/1d499/notifications-alert%403x.png",
              "srcSet": ["/static/74b25d6a91f8d2cd00781ab7976d2b24/e7c18/notifications-alert%403x.png 408w", "/static/74b25d6a91f8d2cd00781ab7976d2b24/b4098/notifications-alert%403x.png 816w", "/static/74b25d6a91f8d2cd00781ab7976d2b24/1d499/notifications-alert%403x.png 1632w", "/static/74b25d6a91f8d2cd00781ab7976d2b24/2ef06/notifications-alert%403x.png 2448w", "/static/74b25d6a91f8d2cd00781ab7976d2b24/2a4bc/notifications-alert%403x.png 3264w", "/static/74b25d6a91f8d2cd00781ab7976d2b24/8b46e/notifications-alert%403x.png 3282w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h4" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Feedback indicators`}</h3>
        <p>{`To communicate feedback on a user action, use a `}<strong parentName="p"><a parentName="strong" {...{
              "href": "/components/toast"
            }}>{`toast`}</a></strong>{`, `}<strong parentName="p"><a parentName="strong" {...{
              "href": "/components/modal"
            }}>{`modal`}</a></strong>{`, `}<strong parentName="p">{`inline messaging`}</strong>{`, and/or `}<strong parentName="p">{`in-page messaging`}</strong>{` (e.g., full-page confirmation).`}</p>
        <p>{`While you’ll only need one type of feedback indicator in most cases, pairing 2 feedback indicators can help give users more clarity in completing a complex flow. For example, when a user submits a long form with errors, you can show an error toast that lets users know they need to fix a form, while also showing inline errors on each incorrect field.`}</p>
        <h4>{`Standard feedback indicator (Toast)`}</h4>
        <p>{`Use a Toast when`}</p>
        <ul>
          <li parentName="ul">{`You’re communicating a direct response to a user action. Example: "asset.jpg" successfully added.`}</li>
          <li parentName="ul">{`You're providing only one link or button to help a user continue a flow.`}</li>
          <li parentName="ul">{`There’s no other way for a user to immediately know that their action was acknowledged. For example, a Toast may not be needed when a modal closes after a user presses “Save” in it. The modal dismissal may sufficiently communicate that the information in the modal was saved, without needing further messaging.`}</li>
        </ul>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.71568627450981%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABQUlEQVQoz52Pu04CQRSG96mMhYnh8g5aWxgRGwQxsaC3E62MrbH0DeQWUJIFAzFRoRG5BHcRFmZ3Z3Z2bp7dwkQTjfjnz5x/zpkvM6MdZs9WV7Zikd1YJBmP7sWjyU+HzcT62vbG5tFB9jSdymf28+nUSSadT+wc53LnWrvVLdzotVq7Wm0Vi3q53KxU7kulRrGg16ptvfFYv33Qn58GU6P3Muq9BjbMqTVDnU5PY5xxKZRSC2T3h6Pxm2FMJlD6g+F8sVChEHVn1tw03w1zAgFj7GIMoOa6GCGHEOoz7hIKlRDP8ygIYwJT23EghB0fDAEOwJ6xACa27VLKfA9Tx1LCE0KKUFJK9bM4FwGMkCu53xn7FyXr6m5OaIDxYPkjzPyuwS7rznUTe+w35jsMz4afqCX1BYbrhAz8L1iqpQzwBxZNHDA0DsihAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen with toast confirming a password reset email has been sent",
              "title": "Screen with toast confirming a password reset email has been sent",
              "src": "/static/5a661bc0a4981acd6c79dfffacb9a4bb/1d499/notifications-toast%403x.png",
              "srcSet": ["/static/5a661bc0a4981acd6c79dfffacb9a4bb/e7c18/notifications-toast%403x.png 408w", "/static/5a661bc0a4981acd6c79dfffacb9a4bb/b4098/notifications-toast%403x.png 816w", "/static/5a661bc0a4981acd6c79dfffacb9a4bb/1d499/notifications-toast%403x.png 1632w", "/static/5a661bc0a4981acd6c79dfffacb9a4bb/2ef06/notifications-toast%403x.png 2448w", "/static/5a661bc0a4981acd6c79dfffacb9a4bb/2a4bc/notifications-toast%403x.png 3264w", "/static/5a661bc0a4981acd6c79dfffacb9a4bb/8b46e/notifications-toast%403x.png 3282w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Toasts in Flex and SendGrid?</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    <Paragraph mdxType="Paragraph">
      While the recommendation is to migrate to Paste Toast components to acknowledge user actions, Flex and SendGrid
      currently use banners for this purpose.
    </Paragraph>
    <Paragraph mdxType="Paragraph">
      These banners generally exist at the top of the page, especially for page-level actions, with a limit of 3 per
      page. The oldest one is removed when a new one displays. In Flex, banners may be placed at the top of a specific
      section where the action was taken.
    </Paragraph>
  </CalloutText>
        </Callout>
        <h4>{`Interruptive feedback indicator (Modal)`}</h4>
        <p>{`Use a Modal when`}</p>
        <ul>
          <li parentName="ul">{`You’re interrupting a user flow.`}</li>
          <li parentName="ul">{`You’re focusing a user’s attention and confirming a definitive action at the end of a Modal flow.`}</li>
          <li parentName="ul">{`You’re providing lengthy text, multiple links, or multiple buttons to help a user continue a flow.`}</li>
        </ul>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.71568627450981%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAAB2ElEQVQoz22R32/SUBTH+Z9MfBor+I/4BpIlc25OY/wPjMAmLWUWppTRhBZWWtuVOt2IYGRbCD9mzByJuj3JwxZDpO3a214PZU6X7Obk3HPu/X7OubknsLy0evvW3fBslAhGQ7P3wkTsDhELQ+DH4CENBaPETIQIRiAIzURADMjDxdXAWqYyF3v2eDm1MB+nKF6Sd0ulmijuiOJ7gder4g4v6DXt44d6p9nogvjB/cTTJ+nFhSTDVANbapNKCWxeXUkWW60+xth1XfDYw57n4b9reri3d7iS5LhibT0nv9VbAalaT8S5DL1JkeV+b3ClAxB9PrUPT6YJQgj2Xu+YTAlZRk5TFUVpBiSpnkxwa5lN8gXf7Xydwp7PN9bLB6/KExa504rd7gTOZWU6XVGvwSke7v49G+O6sk2TpbNzA2KE3KvON8JCx+9s246DkGNaxuD0nXb07fvZpLdt+/AAetwAU6TQbh8hzxuPDdO0TMsyHQewkXFxMvz94+fol4UP2scU+R9cFXfjz4sZugIl9/e/jG3PMCzLssEM4wIje7s/mmOHS9zwkXDOyj2GFpiXUpoqK28a/qhIAb4+/1opsCpX1DY2rlmhoOVZjb00FWQw11xW0vVPfwAZAbP6GAhfzgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Modal congratulating a user at the end of a Modal flow",
              "title": "Modal congratulating a user at the end of a Modal flow",
              "src": "/static/c8704df0af67210c1937ff284ed832b1/1d499/notifications-modal%403x.png",
              "srcSet": ["/static/c8704df0af67210c1937ff284ed832b1/e7c18/notifications-modal%403x.png 408w", "/static/c8704df0af67210c1937ff284ed832b1/b4098/notifications-modal%403x.png 816w", "/static/c8704df0af67210c1937ff284ed832b1/1d499/notifications-modal%403x.png 1632w", "/static/c8704df0af67210c1937ff284ed832b1/2ef06/notifications-modal%403x.png 2448w", "/static/c8704df0af67210c1937ff284ed832b1/2a4bc/notifications-modal%403x.png 3264w", "/static/c8704df0af67210c1937ff284ed832b1/8b46e/notifications-modal%403x.png 3282w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <h4>{`Contextual feedback indicator (Inline messaging)`}</h4>
        <p>{`Use contextual, inline messaging (e.g., inline errors on form fields) when`}</p>
        <ul>
          <li parentName="ul">{`You’re communicating a direct response to a user action.`}</li>
          <li parentName="ul">{`You’re providing links or buttons that are contextual to a UI element and help a user continue a flow.`}</li>
          <li parentName="ul">{`You’re communicating dynamically changing content, like a progress bar.`}</li>
        </ul>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.71568627450981%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABK0lEQVQoz5WR3U7CMBSA91RqvNLJk3Aneis+h+HS5/ASBghRN5dsUTHxxmhgukWy0a5b/+sZGiMCRk++NKen/drT1DputrY26nu7DaBmH9bsgy+gYu/sL1OzG9ub9eZRywqDh07bGw7DwSBwnOtez+/3/G7Xdzre4DzwvJHr3rlXP7m8uLkfPVpCCqmVMQZh/DyeRHEcJ0n08gp5ms2grrU2qwLKFiEFQnlZUiklpYwxTks6T2hRlLCKcQ6Tj1O+B+wHucSYcC7zNEvGEcmQpkyzCgNQWo1CLN8spfqUKROGEjZ9E1km4oTHsZgj01RiDLeslWeIGMlvJ+KkjU/7ecmU0UorZda8dkFGiCjBn6biLCTtUcHlb86qtikz/4wFGXpUVbPV1/wFkN8B6RgWoKpYaW4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen with inline error with anchor on a form field",
              "title": "Screen with inline error with anchor on a form field",
              "src": "/static/a3d2ace96719e734f023bf31788d4050/1d499/notifications-inline-messaging%403x.png",
              "srcSet": ["/static/a3d2ace96719e734f023bf31788d4050/e7c18/notifications-inline-messaging%403x.png 408w", "/static/a3d2ace96719e734f023bf31788d4050/b4098/notifications-inline-messaging%403x.png 816w", "/static/a3d2ace96719e734f023bf31788d4050/1d499/notifications-inline-messaging%403x.png 1632w", "/static/a3d2ace96719e734f023bf31788d4050/2ef06/notifications-inline-messaging%403x.png 2448w", "/static/a3d2ace96719e734f023bf31788d4050/2a4bc/notifications-inline-messaging%403x.png 3264w", "/static/a3d2ace96719e734f023bf31788d4050/8b46e/notifications-inline-messaging%403x.png 3282w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <h4>{`High-emotion feedback indicator (In-page messaging)`}</h4>
        <p>{`Use in-page messaging (e.g., full-page confirmation composition) when`}</p>
        <ul>
          <li parentName="ul">{`You’re communicating a direct response to a `}<em parentName="li">{`high-emotion`}</em>{` user action or flow.`}</li>
          <li parentName="ul">{`You’re providing information that a user might want to copy, screenshot, or save, like a confirmation number or invoice details.`}</li>
        </ul>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "65.19607843137256%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAABvklEQVQoz4WRz3LTMBDG81TQ4UTTPElvtL3SPkhvnHrlAE9AkgLpQJNC22GAgZmSxk5jRbb8V7ZlS7L4VLfQzGSmO59X6539rVZS52D/cOPJ9tbmDtTr7vW6u6va+5fc2tztPn8B9bo7z55u77887Fxe/B70Jycnl6PRRb8/Hgwmx8dnw8FkOJwgRqaNodHH8/Hp91afP337+WPakUrqRhtjMs49QnzG0iylvk8ohV8QAt143rXjxkmCsqZpzK1h7eR5kaa8LAWyAQspDTxCwyhmYQQfRYnW2twbAAs3BkmlFOAyy3IhavxTny1psKQ+ugAmhCJO0gwblGWJD4H1RQES9f9hpVUQWAaoH4ToghjVUZxkPMd08OretG7gHsBKXU2dP1PHmS9YGIdhLKoKo6LXzHEZC9FMStmeGX4V1no6c69nrjtfYNT2Fiwcl1c3CY3sxGnGaynXwDiDH0RxynFGO2fGhbA7C2l4ZYQyD20NHJC5yFijKvOYrcBVVQmpj977r94tjz5Ev7yaCx3lVkXdPA5Xsnn7tXjzpXg9zgGnpWbcCl3Wbn4H4xnwkLLGTahWWkut7qSkrOu1qv8ClzG7ClP/dtUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Full-page congratulations message on setting up a Twilio Messaging Service with SIDs to copy",
              "title": "Full-page congratulations message on setting up a Twilio Messaging Service with SIDs to copy",
              "src": "/static/a123db37a740a4cb3aeb347f5154fa99/1d499/notifications-inpage-messaging%403x.png",
              "srcSet": ["/static/a123db37a740a4cb3aeb347f5154fa99/e7c18/notifications-inpage-messaging%403x.png 408w", "/static/a123db37a740a4cb3aeb347f5154fa99/b4098/notifications-inpage-messaging%403x.png 816w", "/static/a123db37a740a4cb3aeb347f5154fa99/1d499/notifications-inpage-messaging%403x.png 1632w", "/static/a123db37a740a4cb3aeb347f5154fa99/2ef06/notifications-inpage-messaging%403x.png 2448w", "/static/a123db37a740a4cb3aeb347f5154fa99/2a4bc/notifications-inpage-messaging%403x.png 3264w", "/static/a123db37a740a4cb3aeb347f5154fa99/8b46e/notifications-inpage-messaging%403x.png 3282w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h5" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <h2>{`Starter kits`}</h2>
        <h3>{`CodeSandbox`}</h3>
        <p>{`Coming soon`}</p>
        <h3>{`Figma`}</h3>
        <p>{`Coming soon`}</p>
        <h2>{`Related discussions`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/discussions/834"
            }}>{`Thoughts on expanded size variants for <Toast /`}{`>`}</a></li>
        </ul>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      